import React, { useEffect, useState } from "react";
import "../css/PageDepositos.css"

import RowTabla from "../components/RowTabla";
import axios from "../api/axios.js"
import { useParams } from "react-router-dom";

import Wait2 from "../components/Wait2.jsx";
import MiBoton from "../components/MiBoton.jsx";

import { Snackbar, Alert } from "@mui/material";

export default function PageDepositos(props) {
    const [procesando, setProcesando] = useState(false)
    const [mensaje, setMensaje] = useState("")
    const [datos, setDatos] = useState({
        titulo: "Distribuya su depósito",
        titulos: [
            { texto: 'Concepto', ancho: '1fr' },
            { texto: 'Monto', ancho: '150px' }
        ],
        justifica: ['I', 'C'],
        datos: []
    })
    const { token } = useParams()

    useEffect(() => {
        actDatos()
    }, [])

    const blanquea = () => {
        setDatos({
            ...datos,
            datos: []
        })
    }

    const actDatos = async () => {
        if (token == null) {
            blanquea()
            actDatos()
        }
        setProcesando(true)
        let params = new FormData();
        params.append("token", token);

        try {
            const response = await axios.post("/robot/deposito_aplicar_inicio", params)

            const data = response.data;

            if (data.respuesta == "S") {
                let rows = data.lista;
                let nr = []
                rows.forEach((row) => {
                    let item = {
                        id: row.id,
                        columnas: ((row.tipo == 3 || row.tipo == 4 || row.tipo == 5) ? [row.texto, "[valo2]apl"] : [row.texto, "[valor]apl"]),
                        montofijo: row.balance,
                        marca: false,
                        valor: 0.00,
                        presenta: ""
                    }
                    nr.push(item)
                })
                setDatos({
                    ...datos,
                    datos: nr
                })
            } else {
                setMensaje(data.mensaje)
            }
        } catch (error) {
            console.log(error)
            setMensaje(error)
        }

        setProcesando(false)
    }

    const evtClick = () => {

    }

    const calcTotalValores = () => {

    }

    const evtChangeCampos = () => {

    }

    const handleCloseMensaje = () => {
        setMensaje("")
    }

    return (
        <div className="PDep-cuerpo">
            <Wait2 procesando={procesando} />
            <Snackbar
                open={mensaje.length == 0 ? false : true}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleCloseMensaje}
            >
                <Alert severity="error" onClose={handleCloseMensaje} variant="filled">{mensaje}</Alert>
            </Snackbar>
            <div className="PDep-marco">
                <div className="PDep-header">
                    <div className="PDep-logo">
                        <img
                            width={"30px"}
                            height={"30px"}
                            src={"/logo.png"}
                        />
                    </div>
                    <div className="PDep-titulo">
                        ¿Cómo desea aplicar su depósito?
                    </div>

                </div>
                <div className="PDep-body">
                    <RowTabla data={datos} evtClick={evtClick} total_valores={calcTotalValores} evtChangeCampos={evtChangeCampos} />

                </div>
                <div className="PDep-footer">
                    <MiBoton texto="Aplicar" />
                </div>
            </div>
        </div>
    )
}