import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export default function Wait2(props){

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={props.procesando}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}